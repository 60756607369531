/*
 * @Descripttion: 互动中心模块接口
 * @Author: Dyf
 * @Date: 2023-04-12
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-14
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const PREFIX = "/teacher/teainformation/"; // 焦点关注
const PREFIX2 = "/teacher/parmeeting/"; // 家长会

/*******
 * @Descripttion: 资讯列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $newsList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(`${PREFIX}list`, { ...params, pagesize });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取资讯banner
 * @Author: Dyf
 * @return {*}
 */
export async function $newsBanner() {
  try {
    return await HTTP.post(`${PREFIX}get_banner_list`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 点赞/取消点赞
 * @Author: Dyf
 * @param {number} plinf_id 资讯id
 * @return {*}
 */
export async function $likeNews(plinf_id) {
  try {
    return await HTTP.post(`${PREFIX}add_praise`, { plinf_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 资讯详情
 * @Author: Dyf
 * @param {number} plinf_id 资讯id
 * @return {*}
 */
export async function $newsDetail(plinf_id) {
  try {
    return await HTTP.post(`${PREFIX}info`, { plinf_id });
  } catch (error) {
    return error;
  }
}

/* 家长会 */

/*******
 * @Descripttion: 家长会列表
 * @Author: Dyf
 * @param {number} pageindex 页码
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $parentsMeetingList(pageindex, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(`${PREFIX2}list`, { pageindex, pagesize });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 家长会详情
 * @Author: Dyf
 * @param {number} id 家长会id
 * @return {*}
 */
export async function $parentsDetail(id) {
  try {
    return await HTTP.post(`${PREFIX2}info`, { id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新建家长会
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addParentsMeeting(params) {
  try {
    return await HTTP.post(`${PREFIX2}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 家长会家长列表
 * @Author: Dyf
 * @param {number} params.type 列表类型  1. 已确认   2. 未确认
 * @param {number} params.pamee_id 家长会id
 * @return {*}
 */
export async function $parents(params) {
  try {
    return await HTTP.post("/teacher/parmeetingsure/get_sure_list", params);
  } catch (error) {
    return error;
  }
}
