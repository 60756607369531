<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 11:48:11
 * @LastEditTime: 2023-04-11 16:59:24
 * @Descripttion: 互动中心
-->
<style lang="scss" scoped>
.interaction {
    @include innerPage;
    @include pageHead(-42px);

    .page-inner {
        width: calc(100% + 30px);
        height: calc(100% - 196px);
        margin-top: 34px;
        box-sizing: border-box;
        padding-right: 30px;
        overflow: hidden;
        overflow-y: auto;
        @include flexBox(space-between, flex-start);

        .box-head {
            height: 64px;
        }

        .wrapper-left {
            width: 736px;

            .news-swiper {
                width: 100%;
                height: 448px;
                border-radius: 10px;
                margin-bottom: 34px;
                position: relative;

                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }

                .link .el-image {
                    cursor: pointer;
                }

                .el-image {
                    width: 100%;
                    height: 100%;
                }

                .no-data {
                    background: #fff;
                    border-radius: 10px;
                }

                .news-pagination {
                    width: 100%;
                    height: 64px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                    @include flexBox(center);

                    ::v-deep .news-bullet {
                        width: 10px;
                        height: 10px;
                        background-color: #fff;
                        border-radius: 5px;
                        margin: 0 5px;
                        cursor: pointer;

                        &-active {
                            width: 54px;
                        }
                    }
                }
            }

            .parent-interaction {
                width: 100%;
                height: 504px;

                .box-head {
                    border-radius: 10px;
                }

                .interaction-list {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    overflow-y: auto;
                    flex-wrap: wrap;
                    @include flexBox(space-between, flex-start);

                    &--wrapper {
                        width: 100%;
                        height: calc(100% - 96px);
                        margin-top: 32px;

                        &.no-data {
                            border-radius: 10px;
                            background: #fff;

                            .no-data--empty {
                                img {
                                    width: 180px;
                                }
                            }
                        }
                    }

                    &.scroll {
                        box-sizing: border-box;
                        padding-right: 18px;
                    }

                    li {
                        width: calc((100% - 14px) / 2);
                        height: 196px;
                        border-radius: 10px;
                        background: #eff3f9;
                        margin-bottom: 14px;

                        &:nth-last-child(1),
                        &:nth-last-child(2) {
                            margin-bottom: 0;
                        }

                        h5 {
                            width: calc(100% - 40px);
                            height: 62px;
                            margin: 0 auto;
                            color: #474747;
                            box-sizing: border-box;
                            border-bottom: 1px solid rgba($color: #585858, $alpha: .14);
                            @include flexBox;
                        }

                        .contacts {
                            height: calc(100% - 62px);
                            position: relative;
                            @include flexBox;

                            p {
                                width: 50%;
                                font-size: 16px;
                                color: #2a2a2a;
                                line-height: 36px;
                                cursor: pointer;
                                position: relative;
                                flex-direction: column;
                                @include flexBox(center);

                                .pf_bold {
                                    font-size: 24px;
                                    color: #393939;
                                }

                                .badge {
                                    height: 24px;
                                    line-height: 24px;
                                    box-sizing: border-box;
                                    padding: 0 8px;
                                    border-radius: 12px 12px 12px 0;
                                    font-size: 12px;
                                    color: #fff;
                                    background: #f4647c;
                                    position: absolute;
                                    top: -4px;
                                    left: 110px;
                                }

                                &:hover {
                                    color: #6c4ecb;

                                    .pf_bold {
                                        color: #6c4ecb;
                                    }
                                }
                            }

                            &::after {
                                content: '';
                                height: 14px;
                                border-left: 2px solid rgba($color: #585858, $alpha: .14);
                                position: absolute;
                                bottom: 42px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }
        }

        .wrapper-right {
            width: calc(100% - 772px);

            .focus-attention {
                width: 100%;
                height: 448px;
                margin-bottom: 34px;

                .focus-list {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    overflow-y: auto;

                    &--wrapper {
                        width: 100%;
                        height: calc(100% - 64px);
                        box-sizing: border-box;
                        padding-top: 20px;
                        background: #fff;
                        border-radius: 0 0 10px 10px;

                        &.no-data {
                            border-radius: 0 0 10px 10px;
                            background: #fff;

                            .no-data--empty {
                                img {
                                    width: 180px;
                                }
                            }
                        }
                    }

                    li {
                        width: calc(100% - 52px);
                        height: 82px;
                        margin: 0 auto 4px;
                        border-radius: 10px;
                        box-sizing: border-box;
                        padding: 12px 15px;
                        cursor: pointer;
                        border-top: 1px solid rgba($color: #585858, $alpha: .14);

                        .time {
                            height: 24px;
                            margin-bottom: 6px;
                            color: #949495;
                            @include flexBox;

                            .iconfont {
                                font-size: 18px;
                                color: #666;
                                margin-right: 8px;
                            }
                        }

                        .title {
                            height: 22px;
                            @include flexBox;

                            .line-text--1st {
                                max-width: 100%;
                                margin-right: 10px;
                                color: #0b0b0b;
                                font-size: 15px;
                            }

                            .tag {
                                display: inline-block;
                                height: 22px;
                                font-size: 14px;
                                line-height: 22px;
                                padding: 0 6px;
                                border-radius: 0px 11px 11px 11px;
                                color: #fff;
                                background: #ee4f65;
                                flex-shrink: 0;
                            }
                        }

                        &:first-child {
                            border-top: none;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:hover {
                            background: #eff3f9;
                            border-top-color: #eff3f9;

                            .title .line-text--1st {
                                color: #6340c8;
                            }
                        }

                        &:hover+li {
                            border-top-color: transparent;
                        }
                    }
                }
            }

            .class-dynamics {
                width: 100%;
                height: 504px;
                background: #fff;
                border-radius: 10px;

                .dynamic-list {
                    width: calc(100% - 16px);
                    margin: 0 auto 18px;

                    &--wrapper {
                        height: calc(100% - 64px);
                        padding-bottom: 18px;
                        box-sizing: border-box;
                        overflow: auto;
                    }

                    li {
                        width: calc(100% - 22px);
                        margin: 0 auto 8px;
                        box-sizing: border-box;
                        padding: 0 20px;
                        border-bottom: 1px solid rgba($color: #585858, $alpha: .14);

                        &:last-child {
                            margin-bottom: 0;
                            border-bottom: none;
                        }

                        .dynamic-body {
                            width: 100%;
                            height: calc(100% - 74px);

                            h5 {
                                padding-top: 18px;
                                height: 44px;
                                @include flexBox;

                                .iconfont {
                                    font-size: 24px;
                                    color: #d0cbe7;
                                }

                                .bold {
                                    flex-grow: 1;
                                    color: #0b0b0b;
                                    margin: 0 12px;
                                }

                                .tag {
                                    width: 60px;
                                    height: 26px;
                                    line-height: 26px;
                                    text-align: center;
                                    color: #fff;
                                    background: #ee4f65;
                                    border-radius: 15px 15px 15px 0;
                                    font-size: 12px;

                                    &.started {
                                        background: #2ac293;
                                    }

                                    &.ended {
                                        background: #dcdcdc;
                                    }
                                }
                            }

                            .dynamic-desc {
                                color: #424141;
                                line-height: 24px;
                                margin-bottom: 14px;
                                @include ellipsisMultiline(2);
                            }
                        }

                        .dynamic-foot {
                            height: 74px;
                            box-sizing: border-box;
                            border-top: 1px solid rgba($color: #585858, $alpha: .14);
                            @include flexBox;

                            .class {
                                color: #0b0b0b;
                            }

                            .time {
                                margin: 0 auto 0 34px;
                                height: 24px;
                                color: #949495;
                                @include flexBox;

                                .iconfont {
                                    font-size: 18px;
                                    color: #666;
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dialog-container {
    .parents {
        width: calc(100% + 24px);
        max-height: 500px;
        overflow: hidden;
        overflow-y: auto;

        li {
            width: calc(100% - 24px);
            height: 122px;
            border-bottom: 1px solid rgba($color: #585858, $alpha: .14);
            @include flexBox;

            &:last-child {
                border-bottom: none;
            }

            .info {
                margin-left: 10px;
                font-size: 16px;
                color: #2b2b2b;
                flex-direction: column;
                @include flexBox(center, flex-start);

                .time {
                    font-size: 14px;
                    height: 24px;
                    margin-bottom: -6px;
                    color: #949495;
                    @include flexBox;

                    .iconfont {
                        font-size: 18px;
                        color: #666;
                        margin-right: 6px;
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    .not-data {
        height: 300px;
        flex-direction: column;
        @include flexBox(center);

        img {
            width: 125px;
        }

        p {
            color: #b6b6b6;
            margin-top: 16px;
            font-size: 14px;
        }
    }
}
</style>

<template>
    <section class="interaction">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
            </div>
        </div>
        <div class="page-inner">
            <div class="wrapper-left">
                <div class="news-swiper swiper" v-loading="bannerload">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" :class="{ link: item.plban_herf }" v-for="item in bannerData"
                            :key="item.plban_id">
                            <el-image fit='cover' :src="formatfile(item.plban_image)"
                                @click.stop="targetLink(item.plban_herf)" />
                        </div>
                        <div class="swiper-slide no-data" v-if="$isEmpty(bannerData)">
                            <div class="no-data--empty">
                                <img src="@assets/images/no-data3.png" alt />
                                <p>暂无数据哦~</p>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination news-pagination"></div>
                </div>
                <div class="parent-interaction">
                    <h4 class="box-head">
                        <span class="bold">家长互动</span>
                        <el-button type="primary" @click="goChat()" style="margin-right:10px" size="small"
                            v-if="chatNumber > 0">未读({{ chatNumber }})</el-button>
                    </h4>
                    <!-- 班级列表 -->
                    <div class="interaction-list--wrapper" :class="{ 'no-data': $isEmpty(classData) }">
                        <ul class="interaction-list" :class="{ scroll: pageScroll }" ref="interactionList"
                            v-if="!$isEmpty(classData)">
                            <li v-for="item in classData" :key="item.sccla_id">
                                <h5 class="bold">{{ item.sccla_grade_name }}{{ item.sccla_name }}</h5>
                                <div class="contacts">
                                    <p @click="targetMessage('1', item)">
                                        <b class="pf_bold">{{ item.student_num || 0 }}</b>
                                        <span>学生人数</span>
                                        <span class="badge" v-if="item.student_msg_num">消息{{ item.student_msg_num || 0
                                        }}</span>
                                    </p>
                                    <p @click="targetMessage('1', item)">
                                        <b class="pf_bold">{{ item.parent_num || 0 }}</b>
                                        <span>家长人数</span>
                                        <span class="badge" v-if="item.parent_msg_num">消息{{ item.parent_msg_num || 0
                                        }}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                        <div class="no-data--empty" v-if="$isEmpty(classData)">
                            <img src="@assets/images/no-data3.png" alt />
                            <p>暂无班级数据哦~</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper-right">
                <!-- 焦点关注 -->
                <div class="focus-attention">
                    <h4 class="box-head">
                        <span class="bold">焦点关注</span>
                        <router-link class="view-all" :to="{ name: 'INTERACTION_NEWS' }" v-if="newsTotal > newsPageSize">
                            <span>查看全部</span>
                            <span class="iconfont">&#xe601;</span>
                        </router-link>
                    </h4>
                    <div class="focus-list--wrapper" :class="{ 'no-data': $isEmpty(newsData) }">
                        <ul class="focus-list" v-if="!$isEmpty(newsData)">
                            <li v-for=" item  in  newsData " :key="item.plinf_id"
                                @click="$router.push({ name: 'INTERACTION_NEWSDETAIL', params: { id: item.plinf_id } })">
                                <p class="time">
                                    <i class="iconfont">&#xe61a;</i>
                                    <span>
                                        {{ item.create_time | formatTime('MM月DD日') }}
                                        星期{{ item.create_time | formatTime('d') }}
                                        丨{{ item.create_time | formatTime('HH:mm') }}
                                    </span>
                                </p>
                                <p class="title">
                                    <span class="line-text--1st">{{ item.plinf_title }}</span>
                                    <i class="tag" v-if="newsType(item.plinf_type)">
                                        {{ newsType(item.plinf_type) }}
                                    </i>
                                </p>
                            </li>
                        </ul>
                        <div class="no-data--empty" v-if="$isEmpty(newsData)">
                            <img src="@assets/images/no-data3.png" alt />
                            <p>暂无消息哦~</p>
                        </div>
                    </div>
                </div>
                <!-- 班级动态 -->
                <div class="class-dynamics">
                    <h4 class="box-head">
                        <span class="bold">班级动态</span>
                    </h4>
                    <div class="dynamic-list--wrapper" :class="{ 'no-data': $isEmpty(parentMeetingData) }">
                        <template v-if="!$isEmpty(parentMeetingData)">
                            <ul class="dynamic-list" v-infinite-scroll="loadMeeting" infinite-scroll-disabled="disabled"
                                infinite-scroll-distance="1">
                                <li v-for="item in parentMeetingData" :key="item.pamee_id">
                                    <div class="dynamic-body">
                                        <h5>
                                            <i class="iconfont">&#xe642;</i>
                                            <span class="bold line-text--1st">{{ item.pamee_title }}</span>
                                        </h5>
                                        <p class="dynamic-desc light">
                                            {{ item.pamee_remark }}
                                        </p>
                                    </div>
                                    <div class="dynamic-foot">
                                        <span class="class">{{ item.pamee_grade_name }}{{ item.sccla_name }}</span>
                                        <p class="time">
                                            <i class="iconfont">&#xe61a;</i>
                                            <span>
                                                开会时间：
                                                {{ item.pamee_time | formatTime('MM月DD日') }} 星期{{ item.pamee_time |
                                                    formatTime('d') }}
                                                丨{{ item.pamee_time | formatTime('HH:mm') }}
                                            </span>
                                        </p>
                                        <el-button type="custom_primary" size="small"
                                            @click="parentsConfirmed = true; showDialog(item.pamee_id)">
                                            <b class="pf_bold">{{ item.pamee_surenum || 0 }}</b><span>人确认</span>
                                        </el-button>
                                        <el-button type="custom_info" size="small"
                                            @click="parentsConfirmed = false; showDialog(item.pamee_id)">
                                            <b class="pf_bold">{{ item.pamee_nosurenum || 0 }}</b><span>人未确认</span>
                                        </el-button>
                                    </div>
                                </li>
                            </ul>
                            <!-- 加载控件 -->
                            <div class="custom-loading" v-if="parentMeetingLoading">
                                <div class="point-item"></div>
                                <div class="point-item"></div>
                                <div class="point-item"></div>
                            </div>
                            <p class="custom-no-more" v-if="noMore">没有更多了~</p>
                        </template>
                        <div class="no-data--empty" v-if="$isEmpty(parentMeetingData)">
                            <img src="@assets/images/no-data3.png" alt />
                            <p>暂无班级动态哦~</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 家长弹窗 -->
        <el-dialog :title="parentsConfirmed && '已确认家长' || '未确认家长'" :visible.sync="parentsDialog"
            :width="parentsConfirmed && '530px' || '400px'" @close="hideDialog">
            <div class="dialog-container">
                <ul class="parents" v-if="!$isEmpty(parentsData)">
                    <li class="parent" v-for=" item  in  parentsData " :key="item.pamee_id">
                        <el-avatar fit="cover" :size="62" :src="formatfile(item.pauser_image)" class="avatar">
                            <img src="@assets/images/empty_avatar.png" />
                        </el-avatar>
                        <p class="info">
                            <span>{{ item.stuser_name }}家长【{{ item.pauser_name }}】</span>
                            <span class="time" v-if="parentsConfirmed">
                                <i class="iconfont">&#xe61a;</i>确认时间：
                                {{ item.update_time | formatTime('MM月DD日') }} 星期{{ item.update_time | formatTime('d') }}
                                丨{{ item.update_time | formatTime('HH:mm') }}
                            </span>
                        </p>
                    </li>
                </ul>
                <div class="not-data" v-if="$isEmpty(parentsData)">
                    <img src="@assets/images/no-data2.png" alt />
                    <p>暂无家长数据哦~</p>
                </div>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $newsList, $newsBanner, $parentsMeetingList, $parents } from "@api/interaction"
import { $getClass } from "@api/common"
import { formatFile } from "@utils";
import { PAGE_SIZE } from "@config";
import Swiper, { Autoplay, Pagination, Loop } from "swiper";
import "swiper/swiper.min.css";

Swiper.use([Autoplay, Pagination, Loop]);
export default {
    name: 'interaction',
    computed: {
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        },
        newsType() {
            return function (type_key) {
                let type = '';
                switch (type_key) {
                    case 1:
                        type = '学生'
                        break;
                    case 2:
                        type = '家长'
                        break;
                    case 3:
                        type = '平台'
                        break;
                    case 4:
                        type = '教师'
                        break;
                    case 5:
                        type = '学校'
                        break;
                    case 6:
                        type = '教育局'
                        break;
                    default:
                        break;
                }
                return type;
            }
        },
        noMore() {
            return this.parentMeetingData.length >= this.parentMeetingPage.count;
        },
        disabled() {
            return this.parentMeetingLoading || this.noMore
        },
    },
    data() {
        return {
            classData: [], // 班级数据
            bannerData: [], // banner数据
            bannerload: false,
            newsData: [], // 资讯数据
            newsTotal: 0,
            newsPageSize: PAGE_SIZE,
            parentMeetingData: [], // 家长会数据
            parentMeetingLoading: false, // 分页数据加载
            parentMeetingPage: { count: 0, pageIndex: 1 }, // 分页参数
            parentsData: [], // 家长数据
            pageScroll: false, // 区域是否滚动
            parentsDialog: false, // 显示家长弹窗
            parentsConfirmed: false, // 家长弹窗类型  true. 已确认  false. 未确认
            chatNumber: 0,//未读消息数量
        }
    },
    created() {
        this.getClass();
        this.getBanner();
        this.getNews();
        this.getParentMeeting();
        setTimeout(() => {
            // 获取未读消息数量
            this.getChatNumber();
        }, 1000);
    },
    mounted() {
        this.initHeight();
        /* 监听窗口大小变化 */
        window.addEventListener("resize", this.initHeight);
    },
    /** 监听页面离开 */
    beforeRouteLeave(to, from, next) {
        this.$destroy();
        next();
    },
    /** 销毁页面上窗口监听事件 */
    destroyed() {
        window.removeEventListener("resize", this.initHeight);
    },
    methods: {
        /** 获取banner数据 */
        async getBanner() {
            this.bannerload = true;
            let { data } = await $newsBanner();
            this.bannerload = false;
            this.bannerData = data;
            this.$nextTick(() => {
                this.initSwiper();
            })
        },
        /** 获取资讯列表 */
        async getNews() {
            let { data: res } = await $newsList({ pageindex: 1 });
            this.newsData = res.data;
            this.newsTotal = res.allcount;
        },
        /** 获取班级信息 */
        async getClass() {
            // let { data } = await $getClass();
            // this.classData = data
        },
        /** 获取家长会数据 */
        async getParentMeeting() {
            let { data: res } = await $parentsMeetingList(this.parentMeetingPage.pageIndex);
            this.parentMeetingLoading = false; // 数据加载隐藏
            this.parentMeetingPage.count = res.allcount; // 数据总数
            this.parentMeetingData = [...this.parentMeetingData, ...res.data];
        },
        /** 加载更多家长会数据 */
        loadMeeting() {
            this.parentMeetingPage.pageIndex = this.parentMeetingPage.pageIndex + 1
            this.parentMeetingLoading = true;
            setTimeout(() => {
                this.getParentMeeting();
            }, 300);
        },
        /** 实例化swiper */
        initSwiper() {
            this.newsSwiper = new Swiper(".news-swiper", {
                speed: 1200,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                    waitForTransition: true,
                },
                pagination: {
                    el: '.news-pagination',
                    clickable: true,
                    bulletClass: 'news-bullet',
                    bulletActiveClass: 'news-bullet-active',
                },
                preventClicks: true,
            });
        },
        /**
         * banner跳转
         * @param {string} url banner跳转地址
         */
        targetLink(url) {
            if (url) this.$router.push({ path: url });
        },
        /**
         * 跳转聊天页面
         * @param {string} type 消息来源
         * @param {object} data 班级信息
         */
        targetMessage(type, data) {
            this.$router.push({
                name: 'INTERACTION_MESSAGE',
                params: {
                    tab: type,
                    class: `${data.sccla_grade},${data.sccla_id},${data.tencent_im_stu_groupname},${data.tencent_im_par_groupname}`
                }
            })
        },
        goChat() {
            this.$router.push({
                name: 'INTERACTION_MESSAGE',
                params: {
                    tab: 5,
                    class: ""
                }
            })
        },
        /** 获取容器是否滚动 */
        initHeight() {
            this.$nextTick(() => {
                setTimeout(() => {
                    const elH = this.$refs.interactionList;
                    if (elH.scrollHeight > elH.clientHeight) this.pageScroll = true;
                    else this.tableScroll = false;
                    this.$forceUpdate();
                }, 200);
            });
        },
        /** 展开家长列表弹窗 */
        async showDialog(id) {
            let params = {
                pamee_id: id,
                type: this.parentsConfirmed && 1 || 2
            }
            let { data } = await $parents(params);
            this.parentsData = data;
            this.parentsDialog = true;
        },
        /** 关闭家长列表弹窗 */
        hideDialog() {
            this.parentsData = [];
            this.parentsDialog = false;
        },
        //获取聊天数量
        getChatNumber() {
            let _this = this
            if (sessionStorage.getItem("imIsSDKReady")) {
                this.chatNumber = this.tim.getTotalUnreadMessageCount()
                //及时获取新消息数量
                this.tim.on(this.TIM.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, (event) => {
                    _this.chatNumber = event.data
                }, _this);
            }
        },
    },
}
</script>